<template>
  <el-main>
    <!-- <el-card> -->
      <el-tabs v-model="activeName">
        <el-tab-pane label="购买套餐版本" name="first">
          <buy v-if="activeName === 'first'"></buy>
        </el-tab-pane>
        <el-tab-pane label="已购买的套餐" name="second">
          <history-package v-if="activeName === 'second'"></history-package>
        </el-tab-pane>
        <el-tab-pane label="历史订单支付记录" name="third">
          <history-order v-if="activeName === 'third'"></history-order>
        </el-tab-pane>
        <el-tab-pane label="申请发票记录" name="fourth">
          <history-invoice v-if="activeName === 'fourth'"></history-invoice>
        </el-tab-pane>
        <!-- <el-tab-pane label="申请发票记录" name="fourth">申请发票记录</el-tab-pane> -->
      </el-tabs>
    <!-- </el-card> -->
  </el-main>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, reactive, toRefs } from 'vue'

interface IState {
  activeName: string
}

export default defineComponent({
  name: 'Cost',
  components: {
    Buy: defineAsyncComponent(() => import('./components/Buy.vue')),
    HistoryOrder: defineAsyncComponent(() => import('./components/HistoryOrder.vue')),
    HistoryPackage: defineAsyncComponent(() => import('./components/HistoryPackage.vue')),
    HistoryInvoice: defineAsyncComponent(() => import('./components/HistoryInvoice.vue'))
  },
  setup () {
    const state = reactive<IState>({
      activeName: 'first'
    })
    const stateAsRefs = toRefs(state)

    return {
      ...stateAsRefs
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
