
import { defineComponent, defineAsyncComponent, reactive, toRefs } from 'vue'

interface IState {
  activeName: string
}

export default defineComponent({
  name: 'Cost',
  components: {
    Buy: defineAsyncComponent(() => import('./components/Buy.vue')),
    HistoryOrder: defineAsyncComponent(() => import('./components/HistoryOrder.vue')),
    HistoryPackage: defineAsyncComponent(() => import('./components/HistoryPackage.vue')),
    HistoryInvoice: defineAsyncComponent(() => import('./components/HistoryInvoice.vue'))
  },
  setup () {
    const state = reactive<IState>({
      activeName: 'first'
    })
    const stateAsRefs = toRefs(state)

    return {
      ...stateAsRefs
    }
  }
})
